import React from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { InlineIcon } from "@iconify/react"
import zoomIn from "@iconify/icons-bi/zoom-in"
import zoomOut from "@iconify/icons-bi/zoom-out"

import { useWindowSize } from "../../utils/useWindowSize"
import styles from "./pdf-viewer.module.css"

type Props = {
  flyerTerm?: string
  flyerPath?: string
}

const PdfViewer: React.FC<Props> = ({ flyerTerm, flyerPath }) => {
  const [pageNumber, setPageNumber] = React.useState(1)
  const [totalPage, setTotalPage] = React.useState(1)
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const [isLoading, setIsLoading] = React.useState(true)
  const [scale, setScale] = React.useState(1)

  const size = useWindowSize()

  React.useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [size])

  const clickZoomOut = () => {
    setScale(current => (current === 1 ? 1 : current - 0.2))
  }
  const clickZoomIn = () => {
    setScale(current => current + 0.2)
  }
  const onRenderSuccess = () => {
    setIsLoading(false)
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPage(numPages)
  }
  const goToPrevPage = () => {
    setPageNumber(page => {
      if (page === 1) {
        return 1
      } else {
        setIsLoading(true)
        return page - 1
      }
    })
  }
  const goToNextPage = () => {
    setIsLoading(true)
    setPageNumber(page => {
      if (page === totalPage) {
        return totalPage
      } else {
        setIsLoading(true)
        return page + 1
      }
    })
  }

  return (
    <>
      <nav className={styles.actionContainer}>
        <button onClick={goToPrevPage} className={styles.button}>
          前へ
        </button>
        <p className={styles.counter}>
          {pageNumber}/{totalPage}
        </p>
        <button onClick={goToNextPage} className={styles.button}>
          次へ
        </button>
      </nav>
      <div className={styles.scale}>
        <button className={styles.zoomButton} onClick={clickZoomOut}>
          <InlineIcon
            icon={zoomOut}
            style={{ color: "var(--color-primary)" }}
          />
        </button>
        {Math.round(scale * 100)}%
        <button className={styles.zoomButton} onClick={clickZoomIn}>
          <InlineIcon icon={zoomIn} style={{ color: "var(--color-primary)" }} />
        </button>
      </div>
      <div
        className={`${styles.loadingScreen} ${!isLoading && styles.inActive}`}
      >
        PDFを読込中...
      </div>
      <div className={styles.pdfContainer} ref={targetRef}>
        <Document file={flyerPath} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            width={dimensions.width}
            onRenderSuccess={onRenderSuccess}
            scale={scale}
          />
        </Document>
      </div>
      <nav className={styles.actionContainer}>
        <button onClick={goToPrevPage} className={styles.button}>
          前へ
        </button>
        <p className={styles.counter}>
          {pageNumber}/{totalPage}
        </p>
        <button onClick={goToNextPage} className={styles.button}>
          次へ
        </button>
      </nav>
      <div className={styles.scale}>
        <button className={styles.zoomButton} onClick={clickZoomOut}>
          <InlineIcon
            icon={zoomOut}
            style={{ color: "var(--color-primary)" }}
          />
        </button>
        {Math.round(scale * 100)}%
        <button className={styles.zoomButton} onClick={clickZoomIn}>
          <InlineIcon icon={zoomIn} style={{ color: "var(--color-primary)" }} />
        </button>
      </div>
      <div className={styles.actionContainer}>
        <a href={flyerPath} download="flyer.pdf">
          {flyerTerm}全講座案内をダウンロード
        </a>
      </div>
    </>
  )
}

export default PdfViewer
