import React, { useEffect } from "react"
import { connect, ConnectedProps } from "react-redux"

import { store } from "@/types/interface"
import { Layout } from "../components"
import { SEO } from "../components/common"
import { PdfViewer } from "../components/flyer"
import { getSiteInformation } from "../actions"
import styles from "./flyer.module.css"

const connector = connect(
  ({ siteInformation }: { siteInformation: store.SiteInformation }) => ({
    siteInformation: siteInformation.siteInformation,
  }),
  { getSiteInformation }
)

type Props = ConnectedProps<typeof connector>

const Flyer: React.FC<Props> = ({ siteInformation, getSiteInformation }) => {
  useEffect(() => {
    getSiteInformation()
  }, [])

  return (
    <Layout>
      <div className="container">
        <SEO />
        <h1 className={styles.title}>{siteInformation?.flyerTerm}全講座案内</h1>
        <PdfViewer
          flyerTerm={siteInformation?.flyerTerm || ""}
          flyerPath={siteInformation?.flyerPath || ""}
        />
      </div>
    </Layout>
  )
}

export default connector(Flyer)
